/* Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Variables globales */
:root {
  --naranja-anahuac: rgba(255, 38, 0, 1);
  --naranja-anahuac-transparente: rgba(255, 38, 0, 0.5);
  --negro-anahuac: rgba(24, 22, 34, 1);
  --negro-anahuac-transparente: rgba(24, 22, 34, 0.5);
}
/* Estilos globales */
* {
  box-sizing: border-box;
}
html {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: white;
}
body {
  background-color: var(--negro-anahuac);
}
h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-variation-settings: 'wght' 700;
}
h1 {
  font-size: calc(3rem + 1.5vmin);
  line-height: calc(3.5rem + 1.5vmin);
}
h2 {
  font-size: calc(2.5rem + 1.5vmin);
  line-height: calc(3rem + 1.5vmin);
}
h3 {
  font-size: calc(2rem + 1.5vmin);
  line-height: calc(2.5rem + 1.5vmin);
}
h4 {
  font-size: calc(1.5rem + 1.5vmin);
  line-height: calc(2rem + 1.5vmin);
}
h5 {
  font-size: calc(1rem + 1.5vmin);
  line-height: calc(1.5rem + 1.5vmin);
}
h6 {
  font-size: calc(1rem + 1vmin);
  line-height: calc(1.5rem + 1vmin);
}
p {
  font-size: calc(1rem + 1vmin);
  line-height: calc(1.5rem + 1vmin);
}
em {
  font-style: italic;
}
strong {
  font-variation-settings: 'wght' 700;
}
a {
  color: var(--naranja-anahuac);
  transition: font-variation-settings 300ms ease,
              color 200ms ease;
}
a:hover {
  font-variation-settings: 'wght' 800;
}
a:active {
  color: var(--negro-anahuac);
}
/* Variation */
.thin {
  font-variation-settings: 'wght' 200;
}
.light {
  font-variation-settings: 'wght' 300;
}
.regular {
  font-variation-settings: 'wght' 400;
}
.medium {
  font-variation-settings: 'wght' 500;
}
.semibold {
  font-variation-settings: 'wght' 600;
}
.bolder {
  font-variation-settings: 'wght' 800;
}
.heavy {
  font-variation-settings: 'wght' 900;
}
.black {
  font-variation-settings: 'wght' 1000;
}
.ital {
  font-style: italic;
}
/* Color */
.orange {
  color: var(--naranja-anahuac);
}
.negro {
  color: var(--negro-anahuac);
}
