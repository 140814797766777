.App-header {
    background-image: linear-gradient(to bottom, var(--negro-anahuac), rgba(24, 22, 34, 0.9), rgba(255,255,255,0));
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    min-height: 10rem;
    border-bottom: var(--naranja-anahuac) 1px solid;
  }
  
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
  }
  
  .manifiesto {
    font-variation-settings: 'wght' 300;
  }
  
  #header-triangle {
    height: calc(3rem + 1vmin);;
    width: calc(3.5rem + 1vmin);;
  }